import React, { useState } from "react";
import RellevateLogo from "../company-logo.svg";
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import UserService from "../services/UserService";
import { useNavigate } from "react-router-dom";
import RenderOnRole from "./RenderOnRole";
import RenderIfNotOnRole from "./RenderIfNotOnRole";
import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";

export default function RellevateMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(() => ({
      [event.currentTarget.innerText.replace(/\n/g, "")]: event.currentTarget,
    }));
  };

  const handleClose = () => {
    setAnchorEl([]);
  };

  const handleMenuItemClick = (route) => {
    navigate(route);
    handleClose();
  };

  async function fetchEmployerInfo() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/employer`
    );
    return response;
  }

  const {
    data: dataEmployer,
    isLoading: isFetchingEmployer,
    isError: isErrorEmployer,
  } = useQuery(["employerInfo"], fetchEmployerInfo);

  const APPROVE_BUNDLE_ROLE = "pc-approve-bundle";

  const menuItems = [
    {
      label: "Cards",
      subMenus: [
        {
          role: ["pc-query-assigned-paycards"],
          label: "Query available",
          route: "/queryAvailablePaycards",
        },
        {
          role: ["pc-query-available-paycards"],
          label: "Query assigned",
          route: "/queryAssignedPaycards",
        },
        {
          role: [
            "pc-employer-super-admin",
            APPROVE_BUNDLE_ROLE,
            "pc-query-bundle",
          ],
          label: "Request",
          route: "/requestPaycards",
        },
        {
          role: ["pc-program-super-admin", "compliance-mgr"],
          label: "Upload CIP File",
          route: "/cip",
        },
        {
          role: ["pc-program-super-admin", "compliance-mgr"],
          label: "Upload EWA Collection Attempts File",
          route: "/ewa/collectionAttempts",
        },
      ],
    },
    {
      label: "Locations",
      subMenus: [
        {
          role: ["pc-dashboard-locations"],
          label: "View Dashboard",
          route: "/dashboardLocations",
        },
        {
          role: ["pc-query-locations"],
          label: "Query Locations",
          route: "/locations",
        },
      ],
    },
    {
      label: "Payments",
      subMenus: [
        {
          role: ["bl-crud"],
          label: "Manage balances",
          route: "/manageBalance",
        },
        {
          role: ["pf-viewer"],
          notInRole: ["pf-multibalance"],
          label: "Company Balance",
          route: "/balance",
        },
        {
          role: ["pf-multibalance"],
          label: "Company Balances",
          route: "/multiBalance",
        },
        {
          role: ["pf-viewer"],
          label: "Payment Requests",
          route: "/payments/summary",
        },
        {
          role: ["pf-viewer"],
          label: "Query All",
          route: "/payments",
        },
        {
          role: ["pf-viewer"],
          label: "Query Pending",
          route: "/pendingPayments",
        },
        {
          role: ["is-whitelist"],
          label: "Whitelist",
          route: "/whitelist",
        },
      ],
    },
    {
      label: "Members/ Workers",
      subMenus: [
        {
          role: ["employee-viewer"],
          label: "Query Members",
          route: "/companyMembers",
        },
        {
          role: ["pad-viewer"],
          label: "Query Member Info (Internal)",
          route: "/members",
        },
        {
          role: ["pad-viewer"],
          label: "Query Time and Attendance",
          route: "/timeAttendance",
        },
        {
          role: ["pad-viewer"],
          label: "Query Missing Time and Attendance",
          route: "/timeAttendance/missing",
        },
      ],
    },
    {
      label: "Onboarding",
      subMenus: [
        {
          role: ["compliance-mgr"],
          label: "Query Pending Onboardings (Review)",
          route: "/onboardings",
        },
        {
          role: ["onboarding-manage"],
          label: "Query Started Onboardings",
          route: "/onboardings/started",
        },
        {
          role: ["onboarding-manage"],
          label: "Query Started Onboardings (RDS)",
          route: "/onboardings/started/rds",
        },
        {
          role: ["pc-program-super-admin"],
          label: "Bulk Onboardings",
          route: "/onboardings/bulk",
        },
      ],
    },
    {
      label: "Configure",
      subMenus: [
        {
          role: ["config-employer-attr"],
          label: "Employer attributes",
          route: "/employerAttr",
        },
        {
          role: ["config-campaigns"],
          label: "Campaigns",
          route: "/campaignsConfig",
        },
      ],
    },
    {
      label: "Manage Groups",
      subMenus: [
        {
          role: ["group-crud"],
          label: "Company Groups",
          route: "/manageGroup",
        },
        {
          role: ["group-member"],
          label: "Group Members",
          route: "/manageGroup",
        },
      ],
    },
    {
      label: "Reports",
      subMenus: [
        {
          role: ["pc-employer-super-admin", "pc-program-super-admin"],
          label: "Cards Dashboard",
          route: "/cardsDashboard",
        },
        {
          role: ["pc-employer-super-admin", "pc-program-super-admin"],
          label: "Flash Reports",
          route: "/flashReports",
        },
      ],
    },
  ];

  const findRolesForLabel = (label) => {
    const item = menuItems.find((item) => item.label === label);
    if (item) {
      return [
        ...new Set(
          item.subMenus.reduce(
            (roles, subMenu) => [...roles, ...subMenu.role],
            []
          )
        ),
      ];
    }
    return [];
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ bgcolor: "white", boxShadow: "0 10px 8px 0 rgba(0,0,0,.15)" }}
      >
        <Toolbar>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={RellevateLogo}
                alt="Rellevate Logo"
                width="100%"
                loading="lazy"
                style={{
                  marginLeft: 0,
                  maxWidth: "220px",
                }}
              />
              <div style={{ display: "inline-block", marginLeft: "20px" }}>
                <Button onClick={() => handleMenuItemClick("/")}>Home</Button>

                {menuItems.map((item, index) => (
                  <RenderOnRole
                    key={"renderOnRole." + index}
                    roles={findRolesForLabel(item.label)}
                  >
                    <Button
                      key={"button." + index}
                      endIcon={<ArrowDropDownIcon />}
                      onClick={handleClick}
                    >
                      {item.label}
                    </Button>
                    <Menu
                      key={"menu." + index}
                      anchorEl={anchorEl[item.label.toUpperCase()]}
                      open={Boolean(anchorEl[item.label.toUpperCase()])}
                      onClose={handleClose}
                    >
                      {item.subMenus.map((subMenu, index) => {
                        if (subMenu.notInRole !== undefined) {
                          return (
                            <RenderIfNotOnRole
                              key={"renderOnRole." + index}
                              roles={subMenu.notInRole}
                            >
                              <MenuItem
                                key={"menuItem." + index}
                                onClick={() =>
                                  handleMenuItemClick(subMenu.route)
                                }
                              >
                                {subMenu.label}
                              </MenuItem>
                            </RenderIfNotOnRole>
                          );
                        } else {
                          return (
                            <RenderOnRole
                              key={"renderOnRole." + index}
                              roles={subMenu.role}
                            >
                              <MenuItem
                                key={"menuItem." + index}
                                onClick={() =>
                                  handleMenuItemClick(subMenu.route)
                                }
                              >
                                {subMenu.label}
                              </MenuItem>
                            </RenderOnRole>
                          );
                        }
                      })}
                    </Menu>
                  </RenderOnRole>
                ))}
              </div>
            </div>
          </Box>
          <div>
            <Typography
              variant="h8"
              component="div"
              sx={{ flexGrow: 1, color: "grey", marginInline: "2rem" }}
            >
              {dataEmployer?.data?.description}
            </Typography>
          </div>

          <Button
            color="secondary"
            variant="contained"
            sx={{ fontSize: "15px" }}
            onClick={UserService.doLogout}
          >
            SIGN OUT
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
}
